import React, { createContext, useContext }  from 'react';
import useRaContent from './useRaContent';

const RaContentContext = createContext({});

// eslint-disable-next-line
export const RaContentContextProvider = ({ children }) => {
  const { raContent } = useRaContent();

  return (
    <RaContentContext.Provider value={raContent}>
      { children }
    </RaContentContext.Provider>
  );
};

export function useRaContentContext() {
  return useContext(RaContentContext);
}
