import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { useStores } from '../../utils/use-stores';
import Questionnaire from '../../components/common/questionnaire/questionnaire';
import fetchDashboardInfo from '../../utils/dashboard';
import Preloader from '../../components/preloader/preloader';

const Assessment = () => {
  const { assessmentStore, authStore } = useStores();

  useEffect(() => {
    assessmentStore.fetchAssessment();
    if (authStore.check) {
      fetchDashboardInfo(assessmentStore.results);
    }
  }, [assessmentStore, authStore.check]);

  return useObserver(() => {
    if (assessmentStore.loading) return <Preloader />;
    if (assessmentStore.testIsCompleted) return <Redirect to="/results" />;
    return (
      <>
        <Helmet>
          <title>Risk Assessment - Connect2Affect</title>
        </Helmet>
        <Questionnaire
          active={assessmentStore.active}
          next={assessmentStore.next}
          prev={assessmentStore.prev}
          progress={assessmentStore.progress}
          questions={assessmentStore.questions}
          answerQuestion={assessmentStore.answerQuestion}
          disableNext={assessmentStore.disableNext}
          disablePrev={assessmentStore.disablePrev}
          setStep={assessmentStore.setStep}
          inProgressQuiz={assessmentStore.inProgressQuiz}
          setInProgressQuiz={assessmentStore.setInProgressQuiz}
          header={(
            <>
              <h1>Are You Affected by Social Isolation?</h1>
              <p>
                Social isolation is more than feeling lonely. It has a negative
                impact on our emotional and physical health.
              </p>
              <p>
                Take our three-minute assessment to help you understand your risk
                level and receive helpful information and recommendations based on
                your results.
              </p>
            </>
              )}
          footer={(
            <div className={classNames('questions-footer', { hidden: !assessmentStore.lastQuestion })}>
              <div className="content">
                <p>You’re just one step away from getting help with social isolation.</p>
                <button
                  type="button"
                  onClick={assessmentStore.confirmAssessment}
                  disabled={assessmentStore.progress < 100}
                >
                  Get My Results
                </button>
              </div>
            </div>
          )}
        />
      </>
    );
  });
};

export default Assessment;
