import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import './profile.scss';
import { useStores } from '../../utils/use-stores';

const editProfileUrl = `${process.env.REACT_APP_SSO_HOST}/account/editaccount`;

const Profile = () => {
  const { authStore } = useStores();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    (async () => {
      await authStore.fetchProfile();
      reset(authStore.userDetail.preferences);
    })();
  }, [authStore, reset]);

  return useObserver(() => (
    <section className="profile-area">
      <Helmet>
        <title>Profile - Connect2Affect</title>
      </Helmet>
      <div className="container">
        <div className="profile">
          <h1 className="h5 centered">
            Here’s where you’ll find your account profile. If any of the information is incorrect
            or needs updating, please click on “Edit my info” and then “Submit Changes.”
          </h1>
          <div className="info-block">
            <h2 className="h5 underlined">My Info</h2>
            <form>
              <div className="form-row">
                <label>
                  <strong className="label">First Name</strong>
                  <input disabled name="firstName" type="text" defaultValue={authStore.userDetail.first_name} />
                </label>
                <label>
                  <strong className="label">Last Name</strong>
                  <input disabled name="lastName" type="text" defaultValue={authStore.userDetail.last_name} />
                </label>
              </div>
              <div className="form-row">
                <label>
                  <strong className="label">Email</strong>
                  <input disabled name="email" type="email" defaultValue={authStore.userDetail.email} />
                </label>
                <label>
                  <strong className="label">Password</strong>
                  <input disabled name="password" type="password" />
                </label>
              </div>
              <div className="form-row">
                <label>
                  <strong className="label">Zip Code</strong>
                  <input disabled name="zipCode" type="text" defaultValue={authStore.userDetail.zip} />
                </label>
                <label>
                  <strong className="label">Birthdate</strong>
                  <input disabled name="birthDay" type="date" defaultValue={authStore.userDetail.date_of_birth} />
                </label>
              </div>
            </form>
            <a href={editProfileUrl} rel="noopener noreferrer" target="_blank">Edit my info</a>
          </div>

          <div className="topics-block">
            <h2 className="h5 underlined">My Interests</h2>
            <p>Manage your notification preferences here — opt in or out at any time.</p>
            <form onSubmit={handleSubmit(authStore.updateProfile)}>
              <strong className="label">Social Isolation</strong>
              <label className="chk-label">
                <input type="checkbox" name="receive_emails" ref={register} />
                <span>Email</span>
              </label>
              <div className="submit-row">
                <button className="btn-secondary" type="submit">Submit Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  ));
};

export default Profile;
