import Cookie from 'js-cookie';
import qs from 'query-string';

const cookie = Cookie.get();
const params = qs.parse(window.location.search);

const tags = { ...cookie, ...params };

const getUtmData = () => Object.keys(tags).reduce((res, key) => {
  if (key?.includes('utm')) {
    return {
      ...res,
      [key]: tags[key],
    };
  }
  return res;
}, {});

export default getUtmData;
