import {
  action, computed, observable, runInAction,
} from 'mobx';
import { persist } from 'mobx-persist';
import api from '../utils/api';
import QuizStore from './quizStore';
import { go } from '../utils/history';
import { trackEvent } from '../utils/ahoy';
import checkAuth from '../utils/auth';
import activityStore from './activityStore';

class AssessmentStore extends QuizStore {
  @observable testIsCompleted = false;

  @observable loading = false;

  @persist('list') @observable results = [];

  @persist @observable isVoice = false;

  @persist @observable lastSubmission = null;

  @computed get riskLevelInfo() {
    switch (this.riskLevel) {
      case 'low':
        return {
          name: 'Low Risk',
          desk: 'Isolation risk is low. Avoid isolation in the future by becoming aware of the risk factors.',
        };
      case 'medium':
        return {
          name: 'Moderate Risk',
          desk: 'Isolation risk is moderate. Take steps to avoid isolation by becoming aware of the risk factors.',
        };
      case 'high':
        return {
          name: 'High Risk',
          desk: 'Negative consequences of isolation may already be in effect. Take steps to strengthen connections and address the barriers that may be causing isolation.',
        };
      default:
        return {};
    }
  }

  @computed get hasResults() {
    return !!this.results?.length;
  }

  @computed get answers() {
    return {
      origin: this.isVoice ? 'voice_skill' : 'web',
      quiz_id: this.quizId,
      answers: this.results.map((q) => ({ question_id: q.id, option_ids: q.answer })),
    };
  }

  @action setTestIsCompleted = (bool) => {
    this.testIsCompleted = bool;
  };

  @action setLoading = (bool) => {
    this.loading = bool;
  };

  @action confirmAssessment = async () => {
    try {
      if (!checkAuth()) {
        this.results = [...this.questions];
        this.setLastSubmission(null);
      }
      const res = await this.confirmQuiz();
      if (res) {
        this.trackRASubmitted(res?.data);
        this.setRiskLevel(res?.data?.risk_level);
      }
      go('/myresults');
    } catch (e) {
      console.log(e);
    }
  };

  @action fetchAssessment = async () => {
    try {
      const res     = await api.get('/user/pre_assessment');
      const quizId  = res?.data?.quiz_id;

      this.saveQuestions(res?.data?.questions || []);
      this.setQuizId(quizId);
      this.setIsVoice(false);

      this.trackRALoaded(quizId);
    } catch (e) {
      console.log(e);
    }
  };

  @action submitAssessment = async (isAuth) => {
    if (this.fetching || !this.results?.length) return;
    this.fetching = true;
    try {
      const res = await api.post(`/user/${isAuth ? 'assessment' : 'pre_assessment'}`, {
        assessment: this.answers,
        submission_id: this.lastSubmission,
      });
      this.trackRASubmitted(res?.data);
      const submission = res?.data?.submission?.payload?.submission || [];
      if (submission.length) this.setLastAnswers(submission);
      if (isAuth) {
        runInAction(() => { this.results = []; });
        setTimeout(() => activityStore.fetchActivity(), 1200);
      }
      this.setRiskLevel(res?.data?.risk_level);
      this.setLastSubmission(res?.data?.submission_id);
      this.setAccessToken(res?.data?.access_token);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  trackRASubmitted = (data = {}) => {
    trackEvent('risk_assessment/submitted', {
      quiz_id: 1,
      origin: this.isVoice ? 'voice_skill' : 'web',
      risk_level: data?.risk_level,
      submission_id: data?.submission_id,
    });
  };

  trackRALoaded = (quizId) => {
    trackEvent('risk_assessment/loaded', {
      quiz_id: quizId
    });
  };

  @action fetchCheckInResults = async (token) => {
    try {
      const res = await api.get('/user/voice_skill_result', { params: { token } });
      const riskLevel = res?.data?.risk_level;
      if (riskLevel) this.setRiskLevel(riskLevel);
      const { submission } = res?.data?.submission?.payload || {};
      this.setLastAnswers(submission || []);
      this.saveResults(submission || []);
      this.setQuizId(res?.data?.submission?.quiz_id);
      this.setIsVoice(true);
      this.setLastSubmission(res?.data?.submission_id);
      this.trackRASubmitted(res?.data);
    } catch (e) {
      go('/');
    }
  };

  @action saveResults = (submission) => {
    this.results = submission.map((a) => ({
      id: a.question.id,
      answer: [a.option.id],
    }));
  };

  @action setQuizId = (id) => {
    this.quizId = id;
  };

  @action setIsVoice = (isVoice) => {
    this.isVoice = isVoice;
  };

  @action setLastSubmission = (lastSubmission) => {
    this.lastSubmission = lastSubmission;
  };
}

const assessmentStore = new AssessmentStore();
export default assessmentStore;
