import React from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../assets/images/new-logo.png';
import './redirect-loader.scss';

const RedirectLoader = () => (
  <>
    <Helmet>
      <title>Redirect - Connect2Affect</title>
    </Helmet>
    <div className="redirect-loader">
      <img src={logo} alt="connect2affect" />
    </div>
  </>
);

export default RedirectLoader;
