import React from 'react';
import PropTypes from 'prop-types';
import './content-icon.scss';

const ContentIcon = ({ type }) => {
  switch (type) {
    case 'chatbot':
      return (
        <span className="ico">
          <span className="icon-Icon_Chatbot" />
        </span>
      );
    case 'comeback':
      return (
        <span className="ico">
          <span className="icon-Icon_ComeBack" />
        </span>
      );
    case 'covid':
      return (
        <span className="ico">
          <span className="icon-Icon_COVID">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
            <span className="path5" />
            <span className="path6" />
            <span className="path7" />
            <span className="path8" />
            <span className="path9" />
          </span>
        </span>
      );
    case 'dssi':
      return (
        <span className="ico">
          <span className="icon-Icon_DSSI">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
          </span>
        </span>
      );
    case 'report':
      return (
        <span className="ico">
          <span className="icon-Icon_Report">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </span>
        </span>
      );
    case 'friendventory':
      return (
        <span className="ico">
          <span className="icon-Icon_Friendventory" />
        </span>
      );
    // case 'resources':
    //   return (
    //     <span className="ico">
    //       <span className="icon-Icon_Resources" />
    //     </span>
    //   );
    case 'video':
      return (
        <span className="ico">
          <span className="icon-Icon_Video" />
        </span>
      );
    default:
      return (
        <span className="ico">
          <span className="icon-Icon_Resources" />
        </span>
      );
  }
};

ContentIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ContentIcon;
