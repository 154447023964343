import {
  action, observable, computed, runInAction,
} from 'mobx';
import { persist } from 'mobx-persist';
import Cookies from 'js-cookie';
import qs from 'query-string';
import api from '../utils/api'; // eslint-disable-line
import { go, replace } from '../utils/history';
import { configureAhoy } from '../utils/ahoy';

class AuthStore {
  @persist @observable token = JSON.parse(localStorage.getItem('authStore'))?.token;

  @persist('object') @observable user = null;

  @persist('object') @observable metadata = null;

  @observable ssoAuthUrl = '';

  @observable ssoRegisterUrl = '';

  @observable loading = true;

  redirectUrls = {
    '/redirect/video': '/dashboard#video',
    '/redirect/dashboard': '/dashboard',
    '/redirect/results': '/myresults',
    '/redirect/myresults': '/myresults',
    '/redirect/dssi': '/dssi',
    '/redirect/dssi-results': '/dssi-results',
  };

  @computed get check() {
    return !!this.token;
  }

  @computed get userDetail() {
    return this.user || {};
  }

  @computed get meta() {
    return this.metadata || {};
  }

  get redirectUrl() {
    const { pathname, origin } = window.location;
    return this.redirectUrls[pathname] ? `${origin}${pathname}` : '';
  }

  initAuth = async () => {
    await this.checkCode();
    this.fetchAuth();
    configureAhoy(this.token);
  };

  @action checkCode = async () => {
    const { search, pathname } = window.location;
    const { code } = qs.parse(search);
    if (code) {
      const params = {
        code,
        redirect_url: this.redirectUrl,
      };
      if (process.env.NODE_ENV === 'development') params.dev = 1;
      try {
        const res = await api.get('/user/session', { params });
        this.login(res?.data);
        this.redirectTo(pathname);
      } catch (e) {
        replace('/');
      }
    } else if (this.check && this.redirectUrl) {
      this.redirectTo(pathname);
    }
    this.setLoading(false);
  };

  @action fetchAuth = async () => {
    try {
      const res = await api.get('/user/auth_url', {
        params: process.env.NODE_ENV === 'development' ? {
          dev: 1,
        } : {
          redirect_url: this.redirectUrl,
        },
      });
      this.setSSOAuthUrls(res.data || {});
      if (!this.check && this.redirectUrl) {
        window.location.replace(this.ssoAuthUrl);
      } else if (!this.redirectUrl && /^\/redirect/.test(window.location.pathname)) {
        go('/');
      }
    } catch (e) {
      console.log(e);
    }
  };

  @action login = ({ access, user, metadata }) => {
    this.token = access;
    this.user = user;
    this.metadata = metadata;
    Cookies.set('portalUserFirstName', user.first_name, { path: '/', domain: '.connect2affect.org' });
    Cookies.set('portalUserFirstName', user.first_name, { path: '/' });
  };

  @action logout = () => {
    this.token = null;
    this.user = null;
    Cookies.remove('portalUserFirstName', { path: '/', domain: '.connect2affect.org' });
    Cookies.remove('portalUserFirstName', { path: '/' });
    window.location.replace('http://connect2affect.org');
  };

  @action setLoading = (loading) => {
    this.loading = loading;
  };

  @action setSSOAuthUrls = ({ auth_url, login_url, register_url }) => {
    this.ssoAuthUrl = auth_url;
    this.ssoRegisterUrl = register_url || login_url;
  };

  @action fetchProfile = async () => {
    try {
      const res = await api.get('/user/profile');
      runInAction(() => {
        this.user = res.data;
      });
    } catch (e) {
      console.log(e);
    }
  };

  @action updateProfile = async (data) => {
    this.setLoading(true);
    try {
      await api.patch('/user/profile', { preferences: data });
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  };

  redirectTo = (pathname) => go(this.redirectUrls[pathname] || '/dashboard');
}

const authStore = new AuthStore();
export default authStore;
