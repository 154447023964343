import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useStores } from '../../utils/use-stores';
import Questionnaire from '../../components/common/questionnaire/questionnaire';

const DSSI = () => {
  const { dssiStore } = useStores();

  useEffect(() => {
    dssiStore.fetchDSSI();
  }, [dssiStore]);

  return useObserver(() => (
    <>
      <Helmet>
        <title>Social Support Assessment - Connect2Affect</title>
      </Helmet>
      <Questionnaire
        active={dssiStore.active}
        next={dssiStore.next}
        prev={dssiStore.prev}
        progress={dssiStore.progress}
        questions={dssiStore.questions}
        answerQuestion={dssiStore.answerQuestion}
        disableNext={dssiStore.lastQuestion}
        disablePrev={dssiStore.disablePrev}
        setStep={dssiStore.setStep}
        inProgressQuiz={dssiStore.inProgressQuiz}
        setInProgressQuiz={dssiStore.setInProgressQuiz}
        header={(
          <h1>Social Connections Survey</h1>
        )}
        footer={(
          <div className={classNames('questions-footer', { hidden: !dssiStore.lastQuestion })}>
            <div className="content">
              <p>
                Thank you for participating in Connect2Affect and completing this survey. Your
                email address may be used to send you a link to these questions in a few months,
                to see if there are changes in social support among people who participate in the
                Connect2Affect program. Whether or not you complete the questions now or in the
                future will have no effect on whether you may participate in the Connect2Affect
                program or access resources on this website.
              </p>
              <button
                type="button"
                onClick={dssiStore.confirmDSSI}
                // disabled={dssiStore.progress < 100}
              >
                Submit My Answers
              </button>
            </div>
          </div>
        )}
      />
    </>
  ));
};

export default DSSI;
