import {
  action, observable, computed, runInAction,
} from 'mobx';
import api from '../utils/api';

class ActivityStore {
  @observable list = [];

  @computed get activities() {
    return this.list;
  }

  @action fetchActivity = async () => {
    try {
      const res = await api.get('/user/activity');
      runInAction(() => {
        this.list = res.data;
      });
    } catch (e) {
      console.log(e);
    }
  };
}

const activityStore = new ActivityStore();
export default activityStore;
