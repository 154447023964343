import React from 'react';
import { useObserver } from 'mobx-react';
import './preloader.scss';
import CircularProgress from '@material-ui/core/CircularProgress';

const Preloader = () => useObserver(() => (
  <div className="preloader">
    <CircularProgress />
  </div>
));

export default Preloader;
