import React from 'react';
import { useObserver } from 'mobx-react';
import './banner.scss';
import heroBanner from '../../assets/images/dssi-hero-bg.jpg';

const Banner = ({ title }) => useObserver(() => (
  <div className="banner hero-banner">
    <div className="text-holder container">
      <h1>{title}</h1>
    </div>
    <img className="bg-image" src={heroBanner} alt="banner" />
  </div>
));

export default Banner;
