import React from 'react';
import { useObserver } from 'mobx-react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import logo from '../../assets/images/ico-profile.svg';
import './user.scss';
import { useStores } from '../../utils/use-stores';
import { go } from '../../utils/history';

const User = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { authStore } = useStores();

  const openMenu = (event) => setAnchorEl(event.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  const logout = () => {
    authStore.logout();
    closeMenu();
  };

  const dashboard = () => {
    go('/dashboard');
    closeMenu();
  };

  const profile = () => {
    go('/profile');
    closeMenu();
  };

  return useObserver(() => (
    <>
      <button type="button" className="user-nav" onClick={openMenu}>
        <span className="user-name">Hi, {authStore.userDetail.first_name}</span>
        <Avatar alt="John Doe" src={logo} />
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={dashboard}>Activity Center</MenuItem>
        <MenuItem onClick={profile}>My Profile</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  ));
};

export default User;
