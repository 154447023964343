import {
  action, observable, computed, runInAction,
} from 'mobx';
import api from '../utils/api';

class ContentStore {
  @observable list = [];

  @computed get content() {
    return this.list;
  }

  @computed get riskContent() {
    return this.list.filter((c) => c.is_in_risk_panel).slice(0, 3);
  }

  @computed get resourcesContent() {
    return this.list.filter((c) => c.is_in_carousel);
  }

  @computed get alertContent() {
    return this.list.filter((c) => c.is_in_alert_panel).slice(0, 1);
  }

  @action fetchContent = async () => {
    try {
      const res = await api.get('/user/content');
      runInAction(() => {
        this.list = res?.data || [];
      });
    } catch (e) {
      console.log(e);
    }
  };

  @action fetchVideoContent = async () => {
    try {
      const res = await api.get('/user/content/videos');
      return res?.data || [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };
}

const contentStore = new ContentStore();
export default contentStore;
