import React from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';

import { useStores }  from '../../utils/use-stores';
import { useRaContentContext } from '../../utils/raContentContext';

import './result.scss';

const Result = ({ results }) => {
  const { authStore } = useStores();
  const raContent     = useRaContentContext();

  return useObserver(() => (
    <section className="result-area">
      <div className="container">
        <h2 className="underlined">{raContent['risk-assessment-bottom-heading']}</h2>

        <div className="title-row row">
          <div className="col">
            <h3 className="h5">{raContent['risk-assessment-left-col-heading']}</h3>
          </div>
          <div className="col">
            <h3 className="h5">{raContent['risk-assessment-right-col-heading']}</h3>
          </div>
        </div>

        {
          results.map((ar) => (
            <div className="row info" key={ar?.question?.id}>
              <div className="col">
                <p>{ar?.question?.text}</p>
                <span className="txt-lbl">{ar?.option?.text}</span>
                {ar?.option?.value === 0 && (
                  <span className="correct">
                    <span className="icon-ico-check" />
                  </span>
                )}
                {ar?.option?.value >= 1 && (
                  <span className="incorrect">
                    <span className="icon-ico-warning">
                      <span className="path1" />
                      <span className="path2" />
                    </span>
                  </span>
                )}
              </div>
              <div className="col">
                {/* eslint-disable-next-line */}
                <p dangerouslySetInnerHTML={{ __html: ar.option?.advice }} />
              </div>
            </div>
          ))
        }
        <div className="link-row">
          {
            authStore.check
              && <Link to="/" className="link-arrow">Back to Activity Center</Link>
          }
        </div>
      </div>
    </section>
  ));
};

export default Result;
