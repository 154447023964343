import 'react-app-polyfill/ie11';
import 'polyfill-array-includes';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import { history } from './utils/history';
import 'mobx-react/batchingForReactDom';
import { dehydrateStore } from './stores';

(async () => {
  await dehydrateStore();
  ReactDOM.render(
    <Router history={history}>
      <App />
    </Router>, global.document.getElementById('root'),
  );
})();
