import React from 'react';
import { observer, useObserver } from 'mobx-react';
import {
  Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import { useStores } from '../utils/use-stores';
import Header from '../components/header/header';
import Preloader from '../components/preloader/preloader';
import AssessmentResult from '../pages/assessment-result/assessment-result';
import Dashboard from '../pages/dashboard/dashboard';
import Profile from '../pages/profile/profile';
import Footer from '../components/footer/footer';
import DSSI from '../pages/dssi/dssi';
import DSSIResult from '../pages/dssi-result/dssi-result';
import AssessmentResultCheckin from '../pages/assessment-result-checkin/assessment-result-checkin';
import RedirectLoader from '../pages/redirect-loader/redirect-loader';

const AuthorizedLayout = () => {
  const location = useLocation();
  const { authStore } = useStores();

  return useObserver(() => (
    <div className="main-container">
      {authStore.loading && <Preloader />}
      <Header />
      <Switch>
        <Route exact path="/"><Redirect to="/dashboard" /></Route>
        <Route path="/myresults/:id" component={AssessmentResult} />
        <Route path="/myresults" component={AssessmentResult} />
        <Route path="/dssi" component={DSSI} />
        <Route path="/dssi-results/:id" component={DSSIResult} />
        <Route path="/dssi-results" component={DSSIResult} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/profile" component={Profile} />
        <Route path="/checkin" component={AssessmentResultCheckin} />
        <Route path="/redirect/:type" component={RedirectLoader} />
        <Route path="/results"><Redirect to="/myresults" /></Route>
        <Redirect to="/" />
      </Switch>
      {!['/dssi', '/', ''].includes(location.pathname) && <Footer />}
    </div>
  ));
};

export default observer(AuthorizedLayout);
