import React from 'react';
import { useObserver } from 'mobx-react';
import './video-dialog.scss';
import { trackEvent } from '../../utils/ahoy';

const VideoDialog = ({ video, track = true }) => {
  const onVisit = (content) => () => {
    if (track) trackEvent('content/consumed', { content_id: content.id });
  };

  return useObserver(() => (
    <video
      src={video.video_url}
      onPlay={onVisit(video)}
      controls
      poster={video.alert_image_url}
    />
  ));
};

export default VideoDialog;
