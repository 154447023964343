import React from 'react';
import { useObserver } from 'mobx-react';
import logo from '../../assets/images/new-logo.png';
import './header.scss';
import { useStores } from '../../utils/use-stores';
import User from '../user/user';

const Header = () => {
  const { authStore } = useStores();

  return useObserver(() => (
    <div className="header">
      <div className="container">
        <div className="logo">
          <a href="https://connect2affect.org/">
            <img src={logo} alt="connect2affect" />
          </a>
        </div>
        {!authStore.check ? (
          <div className="actions">
            <a href={authStore.ssoAuthUrl || '#'}>Login</a>
          </div>
        ) : <User />}
      </div>
    </div>
  ));
};

export default Header;
