import React from 'react';
import { useObserver } from 'mobx-react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import './resources.scss';
import { useStores } from '../../utils/use-stores';
import { trackEvent } from '../../utils/ahoy';
import VideoDialog from '../video-dialog/video-dialog';

const Resources = () => {
  const { contentStore, dialogStore } = useStores();

  const onVisit = (content) => () => {
    trackEvent('content/consumed', { content_id: content.id });
  };

  const openVideo = (content) => () => {
    dialogStore.openDialog('video', <VideoDialog video={content} />);
  };

  const renderLink = (r) => {
    if (!r.call_to_action) return null;
    if (r.slug === 'dssi_quiz') return <Link to="/dssi">{r.call_to_action}</Link>;
    if (r.slug === 'ra_results') return <Link onClick={onVisit(r)} to="/myresults">{r.call_to_action}</Link>;
    if (r.video_url) return <a onClick={openVideo(r)} href="#video" data-key="video" className="action">{r.call_to_action}</a>;
    return <a onClick={onVisit(r)} href={r.call_to_action_target} className="action" target="_blank" rel="noopener noreferrer">{r.call_to_action}</a>;
  };

  return useObserver(() => (
    <section className="resources-area">
      <div className="container">
        <h2 className="h5">More Resources For You</h2>
        {contentStore.resourcesContent.length ? (
          <Slider
            speed={500}
            variableWidth
            slidesToScroll={1}
            // infinite={false}
          >
            {
              contentStore.resourcesContent.map((r) => (
                <div className="card" key={r.id}>
                  <div className="card-holder">
                    <div>
                      <h3 className="h5 underlined">{r.title}</h3>
                      <h4 className="h2">{r.headline}</h4>
                    </div>
                    <div>
                      <div className="desc">{r.description}</div>
                      {renderLink(r)}
                    </div>
                  </div>
                </div>
              ))
            }
          </Slider>
        ) : null}
      </div>
    </section>
  ));
};

export default Resources;
