import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import DialogContainer from './components/containers/dialog/dialog-container';
import AuthorizedLayout from './layouts/AuthorizedLayout';
import UnAuthorizedLayout from './layouts/UnAuthorizedLayout';
import { useStores } from './utils/use-stores';
import { trackEvent } from './utils/ahoy';
import api from './utils/api';
import utmData from './utils/utmData';
import './assets/scss/style.scss';

const App = () => {
  const { authStore } = useStores();
  useEffect(() => {
    if (Object.keys(utmData).length) {
      trackEvent('utm', utmData);
    }

    authStore.initAuth();
  }, [authStore]);

  return useObserver(() => (
    <>
      {authStore.check ? <AuthorizedLayout /> : <UnAuthorizedLayout />}
      <DialogContainer />
      {localStorage.getItem('dev') && ( // TODO: TEMPORARY SOLUTION
        <button
          onClick={() => api.get('/user/dr0p_act1v1ty_h1st0ry')}
          type="button"
          style={{
            zIndex: 1000,
            position: 'fixed',
            top: '10px',
            left: '10px',
          }}
        >
          Clear history
        </button>
      )}
    </>
  ));
};

export default App;
