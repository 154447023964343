import React      from 'react';
import PropTypes  from 'prop-types';

import './flash.scss';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Flash = ({ flash }) => {
  const messages = Object.entries(flash);

  if (messages.length === 0) return null;

  return (
    <div className="flash">
      {
        messages.map(([type, message]) => (
          <div key={type} className={`flash-${type}`}>
            {message}
          </div>
        ))
      }
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Flash.propTypes = {
  flash: PropTypes.objectOf(PropTypes.string).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Flash;
