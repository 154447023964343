import React from 'react';
import './risks-dialog.scss';
import highRisk from '../../assets/images/high-risk.svg';
import moderateRisk from '../../assets/images/moderate-risk.svg';
import lowRisk from '../../assets/images/low-risk.svg';

const RisksDialog = () => (
  <div className="risks-dialog-body">
    <h2 className="underlined">Risk Levels</h2>
    <div className="row">
      <div className="visual">
        <img src={lowRisk} alt="low risk" />
      </div>
      <div className="text">
        <h5 className="name">Low Risk</h5>
        <p>
          Isolation risk is low. Avoid isolation in the future by becoming aware
          of the risk factors.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="visual">
        <img src={moderateRisk} alt="moderate risk" />
      </div>
      <div className="text">
        <h5 className="name">Moderate Risk</h5>
        <p>
          Isolation risk is moderate. Take steps to avoid isolation by becoming aware of the
          risk factors.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="visual">
        <img src={highRisk} alt="high risk" />
      </div>
      <div className="text">
        <h5 className="name">High Risk</h5>
        <p>
          Negative consequences of isolation may already be in effect.
          Take steps to strengthen connections and address the barriers that may be
          causing isolation.
        </p>
      </div>
    </div>
  </div>
);

export default RisksDialog;
