import React from 'react';
import { useObserver } from 'mobx-react';
import {
  Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import { useStores } from '../utils/use-stores';
import Preloader from '../components/preloader/preloader';
import Header from '../components/header/header';
import Assessment from '../pages/assessment/assessment';
import AssessmentResult from '../pages/assessment-result/assessment-result';
import Footer from '../components/footer/footer';
import RedirectLoader from '../pages/redirect-loader/redirect-loader';
import AssessmentResultCheckin from '../pages/assessment-result-checkin/assessment-result-checkin';

const UnAuthorizedLayout = () => {
  const location = useLocation();
  const { authStore, assessmentStore } = useStores();

  return useObserver(() => (
    <div className="main-container">
      {authStore.loading && <Preloader />}
      <Header />
      <Switch>
        <Route exact path="/" component={Assessment} />
        <Route path="/user/dashboard" component={RedirectLoader} />
        <Route path="/redirect/:type" component={RedirectLoader} />
        {assessmentStore.hasResults && <Route path="/results" component={AssessmentResult} />}
        <Route path="/checkin" component={AssessmentResultCheckin} />
        <Route path="/myresults/:accessToken" component={AssessmentResult} />
        <Route path="/myresults"><Redirect to="/results" /></Route>
        <Redirect to="/" />
      </Switch>
      {!['/dssi', '/'].includes(location.pathname) && <Footer />}
    </div>
  ));
};

export default UnAuthorizedLayout;
