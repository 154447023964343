import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useObserver } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../../utils/use-stores';
import './dialog-container.scss';
import { replace } from '../../../utils/history';

const DialogContainer = ({ className }) => {
  const location = useLocation();
  const { dialogStore } = useStores();
  const closeRef = useRef();
  const [focused, setFocused] = useState(false);

  const dialogClasses = classNames({
    'dialog-window': true,
    [className]: className,
  });

  const close = (key) => () => {
    dialogStore.closeDialog(key);
    const target = document.querySelector(`[data-key="${key}"]`);
    if (target) target.focus();
    replace(location.pathname);
  };

  const onTab = useCallback((e) => {
    const tab = [e.which, e.keyCode].includes(9);
    if (tab && closeRef.current && !focused) {
      setFocused(true);
      closeRef.current.focus();
    }
  }, [focused]);

  useEffect(() => {
    document.addEventListener('keyup', onTab);
    return () => document.removeEventListener('keyup', onTab);
  }, [focused, onTab]);

  return useObserver(() => (
    dialogStore.dialogsList.map((dialog) => (
      <CSSTransition
        key={dialog.key}
        unmountOnExit
        in={dialog.open}
        classNames="dialog"
        timeout={200}
      >
        <div className={dialogClasses}>
          <div className="dialog-overlay" onClick={close(dialog.key)} />
          <div className="scroll-area">
            <div className="indent-area">
              <div className="inner-area">
                <button type="button" onClick={close(dialog.key)} className="close" ref={closeRef}>&nbsp;</button>
                {dialog.component}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    ))
  ));
};

export default DialogContainer;
