import React, { useEffect, useRef, useState } from 'react';
import { useObserver } from 'mobx-react';
import { useDebouncedCallback } from 'use-debounce';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import './questionnaire.scss';
import Questions from '../questions/questions';

const Questionnaire = ({
  header, footer, active, next, prev, progress,
  questions, answerQuestion, disableNext, disablePrev,
  setStep, inProgressQuiz = {}, setInProgressQuiz,
}) => {
  const [iHeight, setIHeight] = useState('900px');
  const [cTranslate, setCTranslate] = useState('translateY(0)');
  const cRef = useRef(null);
  const iRef = useRef(null);
  const hRef = useRef(null);

  const setContainerHeight = (num, item) => {
    const i = item || document.querySelector(`#q-item-${num}`);
    let h = (i?.offsetHeight || 0) + 200;
    if (!num) h += hRef.current.offsetHeight;
    setIHeight(h);
    // iRef.current.style = `height: ${h}px`;
  };

  const scrollTo = (num) => {
    window.scrollTo(0, 0);
    if (num >= 1) {
      document.body.classList.add('hide-header'); // TODO: ie doesn't support .toggle(class, flag)
    } else {
      document.body.classList.remove('hide-header');
    }
    const i = document.querySelector(`#q-item-${num}`);
    if (i) {
      setCTranslate(`translateY(-${num ? i.offsetTop - 100 : 0}px)`);
      // cRef.current.style = `transform: translateY(-${num ? i.offsetTop - 100 : 0}px)`;
      setContainerHeight(num, i);
    }
  };

  const [debouncedCallback] = useDebouncedCallback(() => scrollTo(active), 300);

  useEffect(() => {
    window.addEventListener('resize', debouncedCallback, false);
    return () => window.removeEventListener('resize', debouncedCallback, false);
  }, [active]); // eslint-disable-line

  useEffect(() => {
    const step = Object.keys(inProgressQuiz).length;
    if (step) {
      scrollTo(step);
    }
    setStep(step);
    setContainerHeight(step);
    return () => {
      setInProgressQuiz([]);
      document.body.classList.remove('hide-header');
    };
  }, [questions]); // eslint-disable-line

  const onNext = () => {
    scrollTo(active + 1);
    next();
  };

  const onPrev = () => {
    scrollTo(active - 1);
    prev();
  };

  const onAnswer = (answer) => async () => {
    await answerQuestion(answer);
    onNext();
  };

  return useObserver(() => (
    <section className="questionnaire-intro" style={{ height: iHeight }} ref={iRef}>
      <div className="container" ref={cRef} style={{ transform: cTranslate }}>
        <div ref={hRef}>
          {header}
        </div>
        <Questions
          active={active}
          questions={questions}
          answerQuestion={onAnswer}
        />
        <div id={`q-item-${questions.length}`}>
          {questions.length ? footer : <CircularProgress />}
        </div>
      </div>
      <div className="nav">
        <div className="progress">
          <div className="bar">
            <div className="filler" style={{ width: `${progress}%` }} />
          </div>
          {progress} % Completed
        </div>
        <button className="btn-next" type="button" onClick={onNext} disabled={disableNext}>
          <span className="icon-ico-arrow-down" />
          <i>down</i>
        </button>
        <button className="btn-prev" type="button" onClick={onPrev} disabled={disablePrev}>
          <span className="icon-ico-arrow-up" />
          <i>up</i>
        </button>
      </div>
    </section>
  ));
};

export default Questionnaire;
