import {
  action, computed, observable
} from 'mobx';
import { persist } from 'mobx-persist';

import api from '../utils/api';

class EmailStore {
  @persist @observable email  = '';
  @observable loading         = false;
  @observable flash           = {};

  @computed get collected() {
    return !!this.email;
  }

  @action setLoading = (loading) => {
    this.loading = loading;
  }

  @action setFlash = (flash = {}) => {
    this.flash = flash;
  }

  @action saveEmail = async (data, metadata = {}) => {
    this.setLoading(true);
    this.setFlash();

    try {
      await api.post('/user/submission_emails', data);
      this.setFlash({ success: 'The email was successfully submitted.' });

      // send info to GTM
      window.dataLayer.push({ ...metadata, event: 'results_signup_success' });
    } catch (e) {
      console.log(e);
      this.setFlash({ error: 'The email address was invalid.' });
    } finally {
      this.setLoading(false);
    }
  }
}

const emailStore = new EmailStore();
export default emailStore;
