import api from './api';
import assessmentStore from '../stores/assesmentStore';
import dssiStore from '../stores/dssiStore';
import { replace } from './history';

const fetchDashboardInfo = async (results) => {
  await assessmentStore.setLoading(true);
  try {
    const res = await api.get('/user/dashboard');
    const rl = res?.data?.current_risk_level;
    const raAnswers = res?.data?.latest_risk_assessment_results?.payload?.submission || [];
    const dssiAnswers = res?.data?.latest_dssi_results?.payload?.submission;
    const isComplete = !!raAnswers.length && raAnswers.every((a) => a.option.id);
    if (!isComplete && !results) replace('/');
    if (rl) assessmentStore.setRiskLevel(rl);
    assessmentStore.setLastAnswers(raAnswers);
    assessmentStore.setTestIsCompleted(!!res?.data?.latest_risk_assessment_results?.completed_at);
    dssiStore.setLastAnswers(dssiAnswers);
    dssiStore.setSocialLevels(res?.data?.latest_dssi_results?.payload);
    assessmentStore.setLoading(false);
  } catch (e) {
    assessmentStore.setLoading(false);
    console.log(e);
  }
};

export default fetchDashboardInfo;
