import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import low from '../../assets/images/low-risk.svg';
import moderate from '../../assets/images/moderate-risk.svg';
import high from '../../assets/images/high-risk.svg';
import RisksDialog from '../risks-dialog/risks-dialog';
import { useStores } from '../../utils/use-stores';
import { useRaContentContext } from '../../utils/raContentContext';
import RiskGuest from '../risk-guest/risk-guest';
import EmailForm from '../email-form/email-form';
import { trackEvent } from '../../utils/ahoy';
import './risk.scss';
import VideoDialog from '../video-dialog/video-dialog';
import ContentIcon from '../content-icon/content-icon';

const Risk = ({ hideActions = false, showEmailForm = false }) => {
  const raContent = useRaContentContext();

  const {
    dialogStore, contentStore, authStore, assessmentStore,
  } = useStores();

  useEffect(() => {
    assessmentStore.submitAssessment(authStore.check);
  }, [authStore, assessmentStore]);

  const openRisksDialog = () => {
    dialogStore.openDialog('risk-dialog', <RisksDialog />);
  };

  const onVisit = (content) => () => {
    trackEvent('content/consumed', { content_id: content.id });
  };

  const openVideo = (content) => () => {
    dialogStore.openDialog('video', <VideoDialog video={content} />);
  };

  const renderLink = (r) => {
    if (r.slug === 'dssi_quiz') return <Link to="/dssi">{r.title}</Link>;
    if (r.slug === 'ra_results') return <Link onClick={onVisit(r)} to="/myresults">{r.title}</Link>;
    if (r.video_url) return <a onClick={openVideo(r)} data-key="video" href="#video">{r.title}</a>;
    return <a onClick={onVisit(r)} href={r.call_to_action_target} className="action" target="_blank" rel="noopener noreferrer">{r.title}</a>;
  };

  return useObserver(() => (
    <section className="risk-section">
      <div className="container">
        <h2 className="underlined">{raContent['risk-assessment-top-heading']}</h2>
        <div className="row">
          <div className="col-text">
            {assessmentStore.riskLevel === 'low' && <img src={low} alt="low-risk" />}
            {assessmentStore.riskLevel === 'medium' && <img src={moderate} alt="moderate-risk" />}
            {assessmentStore.riskLevel === 'high' && <img src={high} alt="high-risk" />}
            <div className="info">
              <h3 className="h5">{assessmentStore.riskLevelInfo.name}
                {authStore.check && (
                  <button
                    data-key="risk-dialog"
                    className="popup-opener"
                    type="button"
                    onClick={openRisksDialog}
                  >
                    <span className="icon-ico-question">
                      <span className="path1" /><span className="path2" />
                    </span>
                  </button>
                )}
              </h3>
              <p>
                {raContent[`risk-assessment-risk-${assessmentStore.riskLevel}-copy`]}
              </p>
              <p>{raContent['risk-assessment-supplementary-copy']}</p>
            </div>
          </div>
          <div className="col-actions">
            {
              hideActions
                ? showEmailForm
                  && (
                    <EmailForm  title={raContent['risk-assessment-top-form-title']}
                                copy={raContent['risk-assessment-top-form-copy']}
                                differentiator="top" />
                  )
                : (
                  <div>
                    <h3 className="h5">
                      {
                        authStore.check
                          ? 'Your Next Steps'
                          : 'How to Get Your Results'
                      }
                    </h3>
                    {
                      !authStore.check
                      ? <RiskGuest />
                      : (
                          <ul>
                            {
                              contentStore.riskContent.map((r) => (
                                <li key={r.id}>
                                  <ContentIcon type={r.icon_name} />
                                  {renderLink(r)}
                                </li>
                              ))
                            }
                          </ul>
                        )
                    }
                  </div>
                )
            }
          </div>
        </div>
      </div>
    </section>
  ));
};

export default Risk;
