import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';

import { useObserver }      from 'mobx-react';
import { useStores }        from '../../utils/use-stores';
import { useRaContentContext } from '../../utils/raContentContext';

import Flash                from '../flash/flash';

import './email-form.scss';

/* eslint no-useless-escape: "off" */
const EMAIL_EXPR = /^[a-z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)+$/i;

const isValidFormat = (email) => EMAIL_EXPR.test(email);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

// eslint-disable-next-line object-curly-newline
const EmailForm = ({ layout, title, copy, differentiator }) => {
  const [email, setEmail]               = useState('');
  const [isValid, setIsValid]           = useState(false);
  const { emailStore, assessmentStore } = useStores();
  const raContent                       = useRaContentContext();

  // Ensure the flash doesn't display old content when revisiting the page.
  useEffect(() => {
    emailStore.setFlash();
  }, [emailStore]);

  const handleChange = ({ target }) => {
    const { value } = target;
    setEmail(value);
    setIsValid(isValidFormat(value));
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    emailStore.saveEmail({
      email,
      access_token: assessmentStore.accessToken
    }, { formLocation: differentiator });

    setEmail('');
    setIsValid(false);
  };

  return useObserver(() => (
    <div className={`email-form layout-${layout}`}>
      <Flash flash={emailStore.flash} />

      <h3 className="heading h5">
        <strong>{title}</strong>
      </h3>

      <form action="/email-results" onSubmit={handleSubmit} className="content">
        <p>{copy}</p>

        <div className="field">
          <input  type="email"
                  name="email"
                  value={email}
                  placeholder={raContent['risk-assessment-form-field-label']}
                  onChange={handleChange}
                  className="display-block w-full" />
        </div>

        <div className="buttons">
          <button type="submit"
                  value={differentiator}
                  className="btn-secondary"
                  disabled={!isValid || emailStore.loading}>{raContent['risk-assessment-form-button-label']}</button>
        </div>
      </form>
    </div>
  ));
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EmailForm.defaultProps = {
  layout: 'vertical'
};

EmailForm.propTypes = {
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  title: PropTypes.string,
  copy: PropTypes.string,
  differentiator: PropTypes.string
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EmailForm;
