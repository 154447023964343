import React from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import './footer.scss';
import logo from '../../assets/images/logo-grey.svg';
import iconTwitter from '../../assets/images/icons/ico-twitter.svg';
import iconFacebook from '../../assets/images/icons/ico-facebook.svg';
import { useStores } from '../../utils/use-stores';

const Footer = () => {
  const { authStore } = useStores();
  const currentYear   = new Date().getFullYear();

  return useObserver(() => (
    <footer className="footer">
      <div className="container">
        <img src={logo} alt="connect2affect" />
        <nav className="footer-nav">
          <ul>
            {authStore.check && <li><Link to="/dashboard">Activity Center</Link></li>}
            <li>
              <a href="https://www.aarp.org/about-aarp/privacy-policy/" target="_blank" rel="noopener noreferrer">
                Your Privacy Rights – Privacy Policy
              </a>
            </li>
            <li>
              <a href="https://www.aarp.org/about-aarp/terms-of-service/" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
            </li>
            <li>
              <a href="https://connect2affect.org/media-inquiries/" target="_blank" rel="noopener noreferrer">
                Media Inquiries
              </a>
            </li>
            <li>
              { currentYear } © AARP Foundation
            </li>
          </ul>
        </nav>
        <ul className="social-networks">
          <li>
            <a href="https://twitter.com/aarpfoundation" target="_blank" rel="noopener noreferrer">
              <img src={iconTwitter} alt="twitter" />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/AARPFoundation" target="_blank" rel="noopener noreferrer">
              <img src={iconFacebook} alt="facebook" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  ));
};

export default Footer;
