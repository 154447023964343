import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import './dssi-result.scss';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import Banner from '../../components/banner/banner';
import { useStores } from '../../utils/use-stores';
import fetchDashboardInfo from '../../utils/dashboard';
import { trackEvent } from '../../utils/ahoy';
import VideoDialog from '../../components/video-dialog/video-dialog';
import imgSurvey from '../../assets/images/img-survey.svg';
import icoAbout from '../../assets/images/about-results.svg';
import icoComplete from '../../assets/images/complete-read.svg';
import imgSocRes from '../../assets/images/img-social-results.svg';
import imgSocResPos from '../../assets/images/img-social-results-02.svg';
import imgSocSupport from '../../assets/images/img-social-support.svg';
import imgSocSupportPos from '../../assets/images/img-social-support-02.svg';
import imgExplore from '../../assets/images/img-explore-areas.svg';

const DSSIResult = () => {
  const params = useParams();
  const {
    authStore, dssiStore, contentStore, dialogStore,
  } = useStores();

  useEffect(() => {
    contentStore.fetchContent();
    if (params?.id) {
      dssiStore.fetchResultsById(params?.id);
    } else {
      fetchDashboardInfo(true);
    }
  }, [params, authStore, dssiStore, contentStore]);

  const onVisit = (content) => () => {
    trackEvent('content/consumed', { content_id: content.id });
  };

  const openVideo = (content) => () => {
    dialogStore.openDialog('video', <VideoDialog video={content} />);
  };

  const resetSubmission = () => dssiStore.setSubmissionId(null);

  const renderLink = (r) => {
    if (r.slug === 'dssi_quiz') return <Link to="/dssi">{r.title}</Link>;
    if (r.slug === 'ra_results') return <Link onClick={onVisit(r)} to="/myresults">{r.title}</Link>;
    if (r.video_url) return <a onClick={openVideo(r)} data-key="video" href="#video">{r.title}</a>;
    return <a onClick={onVisit(r)} href={r.call_to_action_target} className="action" target="_blank" rel="noopener noreferrer">{r.title}</a>;
  };

  return useObserver(() => (
    <div className="dssi-result">
      <Helmet>
        <title>DSSI Results - Connect2Affect</title>
      </Helmet>
      <Banner title={`Thanks for ${dssiStore.isCompleteDSSI ? 'Completing' : 'Starting'} the Social Connections Survey`} />
      <div className="container">
        <div className="result-container">
          {dssiStore.isCompleteDSSI ? (
            <>
              <h2 className="underlined">About Your Results</h2>
              <div className="text-holder">
                <p>
                  <div className="ico-box">
                    <img src={icoAbout} alt="about" />
                  </div>
                  The questions you just answered make up a short version of the Duke Social
                  Support Index. This measures the recent interactions you have had with
                  people who don’t live with you, as well as whether you feel supported by
                  the people you consider to be your family and friends. AARP Foundation
                  and its partner organization have been collecting this type of data from
                  older adults for many years, and have observed patterns in how large
                  groups respond to these questions that may be helpful in interpreting
                  your responses.
                </p>
                <p>
                  These interpretations may or may not be completely accurate for your
                  situation, but we hope they are helpful in raising your awareness
                  about social isolation and offer ways to prevent it using resources
                  on the Connect2Affect website.
                </p>
              </div>
            </>
          ) : (
            <>
              <h2 className="underlined">Complete the Survey</h2>

              <div className="text-holder">
                <p>
                  <div className="ico-box">
                    <img src={icoComplete} alt="complete" />
                  </div>
                  Thank you for participating in Connect2Affect. If you would like
                  feedback about your social connections and support, all 10
                  questions must be answered, otherwise responses cannot
                  be calculated. If you would like to complete all the questions
                  on the survey now,
                  <strong> <Link to="/dssi" onClick={resetSubmission}>click here</Link>.</strong>
                </p>
                <p>
                  Whether or not you complete the questions will have no effect
                  on whether you may participate in the Connect2Affect program or access
                  resources on this website.
                </p>
              </div>
            </>
          )}
          <div className="visual">
            <img src={imgSurvey} alt="description" />
          </div>
        </div>
      </div>
      <section className="result-area">
        {dssiStore.isCompleteDSSI ? (
          <div className="container">
            <div className="interaction result-box item-bg">
              <div className="text-holder">
                <h2 className="underlined">Social Interaction Results</h2>
                {dssiStore.socialLevels.interaction === 'high' ? (
                  <div>
                    Your responses indicate you spent time connecting with other people in
                    several different ways last week. Relationships with friends and family
                    members need to be nurtured through time spent together - whether in
                    person or over the phone or computer, in groups or one-on-one. Keep up the
                    good work by making it a daily habit to reach out to one or more people
                    important to you.
                  </div>
                ) : (
                  <div>
                    Your responses indicate that you may not be spending as much time
                    connecting with other people as you need to. Relationships with friends and
                    family members need to be nurtured through time spent together - whether
                    in person or over the phone or computer, in groups or one-on-one. Make it a
                    daily habit to reach out to one or more people important to you.
                  </div>
                )}
              </div>
              <div className="visual">
                <img src={dssiStore.socialLevels.interaction === 'high' ? imgSocResPos : imgSocRes} alt="description" />
              </div>
            </div>
            <div className="support result-box">
              <div className="text-holder">
                <h2 className="underlined">Social Support Results</h2>
                {dssiStore.socialLevels.support === 'high' ? (
                  <div>
                    Your responses also suggest you feel supported and connected in your
                    relationships. It is surprisingly common to feel otherwise, but there are steps
                    you can take to keep your relationships strong and fulfilling. This website
                    offers a range of strategies that can help you maintain strong relationships
                    with your loved ones.
                  </div>
                ) : (
                  <div>
                    Your responses also suggest that you may not feel strongly supported and
                    connected in your relationships. This is a surprisingly common experience,
                    but there are steps you can take to change these feelings. This website offers
                    a range of strategies that can help you build stronger, better relationships
                    with your loved ones.
                  </div>
                )}
              </div>
              <div className="visual">
                <img src={dssiStore.socialLevels.support === 'high' ? imgSocSupportPos : imgSocSupport} alt="description" />
              </div>
            </div>
            <div className="areas result-box item-bg">
              <div className="text-holder">
                <h2 className="underlined">Areas for You to Explore</h2>
                <ul className="links">
                  {contentStore.riskContent.map((l) => <li>{renderLink(l)}</li>)}
                </ul>
              </div>
              <div className="visual">
                <img src={imgExplore} alt="description" />
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="action-row item-bg">
              <Link className="button" to="/dssi" onClick={resetSubmission}>Complete Survey</Link>
            </div>
            <h2 className="underlined">Here&apos;s How You Answered</h2>
            <div className="ttl-row">
              <span>question</span>
              <span>you answered</span>
            </div>
            {
              dssiStore.answersPayload.map((ar) => (
                <div className="row info" key={ar?.question?.id}>
                  <div className="col">
                    <p>{ar?.question?.text}</p>
                  </div>
                  <div className="col">
                    <span className="txt-mob">you answered</span>
                    {ar?.option?.text ? (
                      <span className="txt-lbl">{ar?.option?.text}</span>
                    ) : (
                      <>
                        <span className="ico icon-ico-warning-2">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </span>
                        <span className="txt">You skipped this question</span>
                      </>
                    )}
                  </div>
                </div>
              ))
            }
            <div className="action-row bottom-row">
              <Link className="button" to="/dssi" onClick={resetSubmission}>Complete Survey</Link>
            </div>
          </div>
        )}
        <div className="container">
          <div className="link-row">
            <Link to="/dashboard" className="link-arrow">Back to Activity Center</Link>
          </div>
        </div>
      </section>
    </div>
  ));
};

export default DSSIResult;
