import { configure } from 'mobx';
import React from 'react';
import { create } from 'mobx-persist';
import authStore from './authStore';
import assessmentStore from './assesmentStore';
import activityStore from './activityStore';
import dialogStore from './dialogStore';
import contentStore from './contentStore';
import dssiStore from './dssiStore';
import emailStore from './emailStore';
import raContentStore from './raContentStore';

configure({
  enforceActions: 'observed',
});

const stores = {
  authStore,
  assessmentStore,
  activityStore,
  dialogStore,
  contentStore,
  dssiStore,
  emailStore,
  raContentStore
};

const assessment = create()('assessmentStore', assessmentStore);
create()('authStore', authStore);
create()('dssiStore', dssiStore);

export default stores;
export const storesContext = React.createContext(stores);
export const dehydrateStore = () => Promise.all([assessment]);
