import React from 'react';
import { useObserver } from 'mobx-react';
import './call-to-action.scss';
import VideoAlert from '../video-alert/video-alert';
import Alert from '../alert/alert';
import { useStores } from '../../utils/use-stores';

const CallToAction = () => {
  const { contentStore, authStore } = useStores();

  return useObserver(() => (
    <section className="call-to-action">
      <div className="container">
        {/* eslint-disable-next-line */}
        <h2 className="title">{authStore.meta.signins_count > 1 ? 'Welcome back' : 'Hello'}, {authStore.userDetail?.first_name}</h2>
        {
          contentStore.alertContent.map((a) => (
            a.video_url
              ? <VideoAlert key={a.id} alert={a} />
              : <Alert key={a.id} alert={a} />
          ))
        }
      </div>
    </section>
  ));
};

export default CallToAction;
