import { action, computed } from 'mobx';
import api from '../utils/api';
import { go } from '../utils/history';
import QuizStore from './quizStore';

class DSSIStore extends QuizStore {
  @action confirmDSSI = async () => {
    try {
      const res = await this.confirmQuiz();
      this.setSocialLevels(res?.data?.submission?.payload);
      this.setSubmissionId(null);
      go(`/dssi-results/${res?.data?.submission_id || ''}`);
    } catch (e) {
      console.log(e);
    }
  };

  @action fetchDSSI = async () => {
    try {
      const res = await api.get('/user/quizzes/2');
      this.saveQuestions(res?.data?.questions || []);
      this.setQuizId(res?.data?.quiz_id);
    } catch (e) {
      console.log(e);
    }
  };

  @computed get isCompleteDSSI() {
    return this.answersPayload.every((q) => q.option?.text);
  }
}

const dssiStore = new DSSIStore();
export default dssiStore;
