import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useStores } from '../../utils/use-stores';
import AssessmentResult from '../assessment-result/assessment-result';
import { go } from '../../utils/history';
import Preloader from '../../components/preloader/preloader';

const AssessmentResultCheckin = () => {
  const location = useLocation();
  const { t } = qs.parse(location.search);
  const { assessmentStore, authStore } = useStores();

  useEffect(() => {
    (async () => {
      if (!t) {
        go('/');
        return;
      }
      await assessmentStore.fetchCheckInResults(t);
      if (authStore.check) await go('/dashboard');
    })();
  }, [assessmentStore, t, authStore.check]);

  if (authStore.check) return <Preloader />;

  return <AssessmentResult />;
};

export default AssessmentResultCheckin;
