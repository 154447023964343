import ahoy from 'ahoy.js';
import getUtmData from './utmData';

ahoy.configure({
  startOnReady: false,
});

export const configureAhoy = (token) => {
  const headers = {};
  if (token) headers.headers = { Authorization: `Bearer ${token}` };

  ahoy.configure({
    // urlPrefix: "",
    visitsUrl: `${process.env.REACT_APP_BASE_URL}/user/track/visits`,
    eventsUrl: `${process.env.REACT_APP_BASE_URL}/user/track/events`,
    ...headers,
    // page: null,
    // platform: "Web",
    // useBeacon: true,
    // startOnReady: true,
    // trackVisits: true,
    // cookies: true,
    // cookieDomain: null,
    // headers: {},
    visitParams: getUtmData(),
    // withCredentials: false,
    // visitDuration: 4 * 60, // 4 hours
    // visitorDuration: 2 * 365 * 24 * 60 // 2 years
  });

  if (process.env.NODE_ENV === 'development') ahoy.debug();
  ahoy.start();

  window.ahoy = ahoy;
};

export const trackEvent = (name, properties) => ahoy.track(name, properties);

export default ahoy;
