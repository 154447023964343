import { useEffect, useState } from 'react';
import { useStores } from './use-stores';

export default function useRaContent() {
  const { raContentStore }    = useStores();
  const [content, setContent] = useState({});

  useEffect(() => {
    const loadContent = async () => {
      await raContentStore.fetchContent();

      const contentMapping = Object.fromEntries(
        raContentStore.contentItems.map(({ slug, value }) => ([slug, value]))
      );

      setContent(contentMapping);
    };

    loadContent();
  }, [raContentStore]);

  return { raContent: content };
}
