import React from 'react';
import { useObserver } from 'mobx-react';
import classNames from 'classnames';
import './questions.scss';

const Questions = ({ questions, answerQuestion, active }) => useObserver(() => (
  <div className="intro-questions">
    {
      questions.map((q, i) => (
        <div
          className={classNames({
            question: true,
            disabled: active !== i,
          })}
          id={`q-item-${i}`}
          key={q.id}
        >
          {q.instruction && <p className="instruction">{q.instruction}</p>}
          <h2 className="h3">
            {q.question_type === 'question' && <span className="color-red">{i + 1}. </span>}
            {q.text}
          </h2>
          <div className="actions">
            {
              q.options.map((a) => (
                <button
                  className={classNames({
                    active: q.answer.includes(a.id),
                  })}
                  disabled={active !== i}
                  key={a.position}
                  type="button"
                  onClick={answerQuestion(a)}
                >
                  {a.text}
                </button>
              ))
            }
          </div>
        </div>
      ))
    }
  </div>
));

export default Questions;
