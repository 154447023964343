import {
  action, observable, computed,
} from 'mobx';
import { persist } from 'mobx-persist';
import api from '../utils/api';
import checkAuth from '../utils/auth';
import { go } from '../utils/history';

class QuizStore {
  @observable fetching = false;

  @observable activeQuestion = 0;

  @observable list = [];

  @observable submissionId = null;

  @observable inProgressQuiz = {};

  @persist @observable quizId = null;

  @persist('list') @observable answersPayload = [];

  @persist('object') @observable socialLevels = {};

  @persist @observable riskLevel = null;

  @persist @observable accessToken = null;

  @computed get questions() {
    return this.list;
  }

  @computed get currentQ() {
    return this.questions[this.active] || {};
  }

  @computed get active() {
    return this.activeQuestion;
  }

  @computed get progress() {
    const answered = this.questions.filter((q) => q.answer.length);
    return Math.round((answered.length / this.questions.length) * 100) || 0;
  }

  @computed get submission() {
    return {
      quiz_id: this.quizId,
      payload: this.questions.map((q) => ({ question_id: q.id, option_ids: q.answer })),
    };
  }

  @computed get answersPayloadInfo() {
    return this.questions.map((q) => ({
      option: { text: q.answerText[0] },
      question: { id: q.id, text: q.text },
    }));
  }

  @computed get lastQuestion() {
    return this.active === this.questions.length;
  }

  @computed get disableNext() {
    return !this.currentQ.answer?.length || this.lastQuestion;
  }

  @computed get disablePrev() {
    return !this.activeQuestion;
  }

  @action setSubmissionId = (id) => {
    this.submissionId = id;
  };

  @action answerQuestion = async ({ id, text }) => {
    this.questions[this.activeQuestion].answer = [id];
    this.questions[this.activeQuestion].answerText = [text];
    // this.next();
    if (checkAuth()) await this.saveAssessment();
  };

  @action next = () => {
    if (this.activeQuestion < this.questions.length) this.activeQuestion += 1;
  };

  @action prev = () => {
    if (this.activeQuestion > 0) this.activeQuestion -= 1;
  };

  @action setStep = (step) => {
    this.activeQuestion = step < 0 ? 0 : step;
  };

  @action setLastAnswers = (answers = []) => {
    this.answersPayload = answers;
  };

  @action saveQuestions = (questions) => {
    const copy = [...questions];
    const res = copy.map((q, i) => {
      if (q.question_type === 'instructions') copy[i + 1].instruction = q.text;
      return {
        ...q,
        answer: this.inProgressQuiz[q.id]?.answer || [],
        answerText: this.inProgressQuiz[q.id]?.answerText || [],
      };
    });
    this.list = res.filter((q) => q.question_type === 'question');
  };

  @action saveAssessment = async () => {
    try {
      if (this.submissionId) {
        api.patch(`/user/submission/${this.submissionId}`, this.submission);
      } else {
        const res = await api.post('/user/submission', this.submission);
        this.submissionId = res?.data?.id;
      }
    } catch (e) {
      console.log(e);
    }
  };

  confirmQuiz = async () => {
    try {
      this.setLastAnswers([...this.answersPayloadInfo]);
      if (!checkAuth()) return null;
      const res = await api.post(`/user/submission/${this.submissionId}/complete`, this.submission);
      const submission = res?.data?.submission?.payload?.submission || [];
      if (submission.length) this.setLastAnswers(submission);
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  @action setRiskLevel = (level) => {
    this.riskLevel = level;
  };

  @action setAccessToken = (token) => {
    this.accessToken = token;
  }

  @action fetchResultsById = async (id) => {
    if (!id) return;
    try {
      const res = await api.get(`/user/submission/${id}`);
      this.setLastAnswers(res?.data?.payload?.submission || []);
      const riskLevel = res?.data?.payload?.risk_level;
      if (riskLevel) this.setRiskLevel(riskLevel);
      this.setSocialLevels(res?.data?.payload);
    } catch (e) {
      go('/dashboard');
      console.log(e);
    }
  };

  @action fetchResultsByToken = async (token) => {
    if (!token) return;
    try {
      const res         = await api.get(`/user/submission_results/${token}`);
      const payload     = res?.data?.payload;
      const submission  = payload?.submission;
      const riskLevel   = payload?.risk_level;

      this.setLastAnswers(submission || []);
      if (riskLevel) this.setRiskLevel(riskLevel);

      this.setSocialLevels(payload);
    } catch (e) {
      go('/');
      console.log(e);
    }
  }

  @action continueQuiz = ({ id, payload } = {}) => {
    this.setSubmissionId(id);
    const res = {};
    (payload?.submission || []).forEach(({ option, question }) => {
      if (option.id !== null) {
        res[question.id] = {
          answer: [option.id],
          answerText: [option.text],
        };
      }
    });
    this.setInProgressQuiz(res);
  };

  @action setInProgressQuiz = (res = {}) => {
    this.inProgressQuiz = res;
  };

  @action setQuizId = (id) => {
    this.quizId = id;
  };

  @action setSocialLevels = (levels = {}) => {
    this.socialLevels = {
      interaction: levels.social_interaction_level,
      support: levels.subjective_support_level,
    };
  };
}

export default QuizStore;
