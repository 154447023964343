import React, { useEffect } from 'react';
import { useObserver }      from 'mobx-react';
import { useParams }        from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import Banner               from '../../components/banner/banner';
import Risk                 from '../../components/risk/risk';
import Result               from '../../components/result/result';
import EmailForm            from '../../components/email-form/email-form';
import { useStores }        from '../../utils/use-stores';
import { RaContentContextProvider, useRaContentContext } from '../../utils/raContentContext';
import fetchDashboardInfo   from '../../utils/dashboard';
import { trackEvent }       from '../../utils/ahoy';
import callDelighted        from '../../utils/delighted';

import './assessment-result.scss';

const BareAssessmentResult = () => {
  const params = useParams();
  const { authStore, assessmentStore }  = useStores();
  const raContent                       = useRaContentContext();

  useEffect(callDelighted, []);

  useEffect(() => {
    if (authStore.check) {
      if (params?.id) {
        assessmentStore.fetchResultsById(params.id);
        trackEvent('content/consumed', { content_id: 1 });
      } else {
        fetchDashboardInfo(assessmentStore.results.length);
      }
    } else if (params?.accessToken) {
      assessmentStore.fetchResultsByToken(params.accessToken);
      trackEvent('content/consumed', { content_id: 1 });
    }
  }, [authStore, assessmentStore]); // eslint-disable-line

  const showEmailForm = !authStore.check
                        && !params?.accessToken
                        && !params?.id;

  return useObserver(() => (
    <div className="assessment-result">
      <Helmet>
        <title>Results - Connect2Affect</title>
      </Helmet>
      <Banner title={raContent['risk-assessment-page-title']} />
      <Risk hideActions showEmailForm={showEmailForm} />
      <Result results={assessmentStore.answersPayload} />

      {
        showEmailForm
        && (
          <div className="container">
            <EmailForm  layout="horizontal"
                        title={raContent['risk-assessment-bottom-form-title']}
                        copy={raContent['risk-assessment-bottom-form-copy']}
                        differentiator="bottom" />
          </div>
        )
      }
    </div>
  ));
};

const AssessmentResult = () => (
  <RaContentContextProvider>
    <BareAssessmentResult />
  </RaContentContextProvider>
);
export default AssessmentResult;
