import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useStores } from '../../utils/use-stores';
import icoCheckbox from '../../assets/images/icons/ico-checkbox.svg';
import './activity.scss';
import { go } from '../../utils/history';
import VideoDialog from '../video-dialog/video-dialog';

const ITEMS_TO_SHOW = 6;

const Activity = () => {
  const {
    assessmentStore, dssiStore, activityStore, dialogStore,
  } = useStores();
  const [isAll, setIsAll] = useState(false);
  const numToShow = isAll ? Infinity : ITEMS_TO_SHOW;

  const showAll = () => setIsAll(true);

  const openVideo = (a) => () => {
    dialogStore.openDialog('video', <VideoDialog video={a.content || {}} />);
  };

  const continueQuiz = (a) => (e) => {
    e.preventDefault();
    if (a.type === 'dssi') {
      dssiStore.continueQuiz(a.submission);
      go('/dssi');
    } else {
      assessmentStore.continueQuiz(a.submission);
      go('/');
    }
  };

  const renderItem = (a, key) => {
    switch (a.type) {
      case 'video':
        return (
          <li key={key}>
            <img className="ico" src={icoCheckbox} alt="chk" />
            <span>You watched {a.title} on</span>
            <span className="date">{a.date}</span>
            <a href="#video" onClick={openVideo(a)}>Watch again</a>
          </li>
        );
      case 'article':
        return (
          <li key={key}>
            <img className="ico" src={icoCheckbox} alt="chk" />
            <span>You {a.content?.slug === 'aunt_bertha_link' ? 'searched' : 'read'} {a.title} on</span>
            <span className="date">{a.date}</span>
            <a href={a.content?.call_to_action_target || '#action'} target="_blank" rel="noopener noreferrer">
              {a.content?.slug === 'aunt_bertha_link' ? 'Search' : 'Read'} again
            </a>
          </li>
        );
      case 'risk_assessment':
      case 'dssi':
        if (a.completed) {
          return (
            <li key={key}>
              <img className="ico" src={icoCheckbox} alt="chk" />
              <span>You took a {a.title} on</span>
              <span className="date">{a.date}</span>
              <Link to={`/${a.type === 'dssi' ? 'dssi-results' : 'myresults'}/${a.submission?.id}`}>See results</Link>
            </li>
          );
        }
        return (
          <li key={key}>
            <img className="ico" src={icoCheckbox} alt="chk" />
            <span>You started a {a.title} on</span>
            <span className="date">{a.date}</span>
            <a href="#finish" onClick={continueQuiz(a)}>Finish it</a>
          </li>
        );
      // case 'profile':
      //   return (
      //     <li key={a.id}>
      //       <span className="ico icon-ico-profile" />
      //       <span>You changed profile </span>
      //     </li>
      //   );
      default:
        return (
          <li key={key}>
            <img className="ico" src={icoCheckbox} alt="chk" />
            <span>You accessed {a.title} on</span>
            <span className="date">{a.date}</span>
            {/* eslint-disable-next-line */}
            <a href={a.content?.call_to_action_target} target="_blank" rel="noopener noreferrer">View again</a>
          </li>
        );
    }
  };

  return useObserver(() => {
    if (!activityStore.activities.length) return null;
    return (
      <section className="activity-area">
        <div className="container">
          <div className="block">
            <h2 className="h5 underlined">Recent Activity</h2>
            <ul className="chk-list">
              {
              activityStore.activities.slice(0, numToShow).map((a, i) => renderItem(a, i))
            }
            </ul>
            {!isAll && activityStore.activities.length > ITEMS_TO_SHOW && (
              <button
                type="button"
                className="link-more"
                onClick={showAll}
              >
                See more &gt;
              </button>
            )}
          </div>
        </div>
      </section>
    );
  });
};

export default Activity;
