import { action, observable, runInAction } from 'mobx';
import api from '../utils/api';

const API_PATH = '/user/risk_assessment_content';

class RAContentStore {
  @observable contentItems = [];

  @observable fetching = false;

  @action setFetching = (fetching) => {
    this.fetching = fetching;
  };

  @action fetchContent = async () => {
    this.setFetching(true);

    try {
      const { data } = await api.get(API_PATH);
      runInAction(() => {
        this.contentItems = data?.content_items;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };
}

const raContentStore = new RAContentStore();
export default raContentStore;
