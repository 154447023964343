import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useStores } from '../../utils/use-stores';
import Risk from '../../components/risk/risk';
import CallToAction from '../../components/call-to-action/call-to-action';
import Resources from '../../components/resources/resources';
import Activity from '../../components/activity/activity';
import VideoDialog from '../../components/video-dialog/video-dialog';
import './dashboard.scss';
import fetchDashboardInfo from '../../utils/dashboard';

const Dashboard = () => {
  const location = useLocation();
  const {
    activityStore, contentStore, dialogStore, assessmentStore,
  } = useStores();

  const handleVideo = async () => {
    if (location.hash !== '#video') return;
    const videos = await contentStore.fetchVideoContent();
    const video = videos?.find((c) => c.video_url);
    dialogStore.openDialog('video', <VideoDialog video={video} />);
  };

  useEffect(() => {
    contentStore.fetchContent();
    fetchDashboardInfo(assessmentStore.results.length);
  }, [contentStore, assessmentStore]);

  useEffect(() => {
    activityStore.fetchActivity();
    handleVideo();
  }, [activityStore]); // eslint-disable-line

  return useObserver(() => (
    <div className="dashboard">
      <Helmet>
        <title>Dashboard - Connect2Affect</title>
      </Helmet>
      <CallToAction />
      <Risk />
      <Resources />
      {/* <Progress /> */}
      <Activity />
    </div>
  ));
};

export default Dashboard;
