import {
  action, computed, observable,
} from 'mobx';

class DialogStore {
  @observable dialogs = [
    // {
    //   key: 'dialog',
    //   component: null,
    //   open: true,
    // },
  ];

  @action openDialog = (dialogName, component) => {
    const index = this.dialogs.findIndex((dialog) => dialog.key === dialogName);
    const dialog = {
      key: dialogName,
      open: false,
      component,
    };
    if (index > -1) {
      this.dialogs.splice(index, 1, dialog);
      this.openWithDelay(index);
    } else {
      this.dialogs.push(dialog);
      this.openWithDelay(this.dialogs.length - 1);
    }
  };

  @action closeDialog = (dialogType) => {
    this.dialogs.find((dialog) => dialog.key === dialogType).open = false;
  };

  @computed get dialogsList() {
    return this.dialogs;
  }

  openWithDelay = (i) => {
    setTimeout(action(() => { this.dialogs[i].open = true; }));
  }
}

const dialogStore = new DialogStore();
export default dialogStore;
