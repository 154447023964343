import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import './alert.scss';
import { Link } from 'react-router-dom';
import { trackEvent } from '../../utils/ahoy';

const Alert = ({ alert }) => {
  const [showAction, setShowAction] = useState(true);
  const close = () => setShowAction(false);

  const onVisit = (content) => () => {
    trackEvent('content/consumed', { content_id: content.id });
  };

  const renderLink = (r) => {
    if (r.slug === 'dssi_quiz') return <Link to="/dssi" className="button btn-white">{r.call_to_action}</Link>;
    if (r.slug === 'ra_results') return <Link to="/myresults" onClick={onVisit(r)} className="button btn-white">{r.call_to_action}</Link>;
    return (
      <a
        className="button btn-white"
        onClick={onVisit(r)}
        href={r.call_to_action_target}
        target="_blank"
        rel="noopener noreferrer"
      >
        {r.call_to_action}
      </a>
    );
  };

  return useObserver(() => showAction && (
    <div className="action-block">
      {alert.alert_image_url && (
        <div className="image-holder">
          <img src={alert.alert_image_url} alt="alertImage" />
        </div>
      )}
      {!alert.alert_image_url && <span className="decor icon-ico-pencil" />}
      <div className="text-holder">
        <h2 className="h5">{alert.title}</h2>
        <p>{alert.description}</p>
      </div>
      <div className="btn-wrap">
        {renderLink(alert)}
      </div>
      <button className="btn-close" onClick={close} type="button">X</button>
    </div>
  ));
};

export default Alert;
