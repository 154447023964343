import React from 'react';
import { useObserver } from 'mobx-react';
import './risk-guest.scss';
import icoProfile from '../../assets/images/icons/ico-profile.svg';
import icoExplore from '../../assets/images/icons/ico-explore.svg';
import icoGetHelp from '../../assets/images/icons/ico-get-help.svg';
import { useStores } from '../../utils/use-stores';

const RiskGuest = () => {
  const { authStore } = useStores();

  return useObserver(() => (
    <ul className="guest-actions">
      <li>
        <span className="ico">
          <img src={icoProfile} alt="profile" />
        </span>
        <a href={authStore.ssoRegisterUrl}>Create Free Account</a>
      </li>
      <li>
        <span className="ico">
          <img src={icoExplore} alt="Connection" />
        </span>
        <a href={authStore.ssoRegisterUrl}>Visit Activity Center</a>
      </li>
      <li>
        <span className="ico">
          <img src={icoGetHelp} alt="help" />
        </span>
        <a href={authStore.ssoRegisterUrl}>See Recommendations </a>
      </li>
    </ul>
  ));
};

export default RiskGuest;
