import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { trackEvent } from '../../utils/ahoy';
import './video-alert.scss';

const VideoAlert = ({ alert }) => {
  const [showAction, setShowAction] = useState(true);
  const close = () => setShowAction(false);

  const onPlay = (content) => () => {
    trackEvent('content/consumed', { content_id: content.id });
  };

  return useObserver(() => showAction && (
    <div className="intro">
      <div className="video-holder">
        <video
          src={alert.video_url}
          controls
          onPlay={onPlay(alert)}
          poster={alert.alert_image_url}
        />
      </div>
      <div className="text-holder">
        <h3 className="h5">{alert.title}</h3>
        <p>{alert.description}</p>
        <button onClick={close} type="button" className="btn-close">X</button>
      </div>
    </div>
  ));
};

export default VideoAlert;
